export default function ({ route, redirect }) {
  // Redirect /chicas-de-holanda to /acties/chicas-de-holanda
  if (route.path === '/chicas-de-holanda' || route.path.startsWith('/chicas-de-holanda/')) {
    const suffix = route.path.replace('/chicas-de-holanda', '');
    redirect(`/acties/chicas-de-holanda${suffix}`);
    return;
  }

  // Existing redirect for /categorieen and /programmas
  const pathMatch = route.path.match(/^\/(?:categorieen|programmas)(?:\/([\w-]+))?$/);
  if (pathMatch) {
    const slug = pathMatch[1];
    const newPath = route.path.includes('categorieen')
      ? slug
        ? `/genres/${slug}`
        : '/genres'
      : slug
        ? `/series/${slug}`
        : '/series';
    redirect(newPath);
  }
}
